<template>
  <div class="bg-gray-100 px-4 pt-16 lg:px-16 pb-2">
    <h1 class="text-4xl font-poppins font-normal">Your Dashboard</h1>
    <section class="grid grid-cols-2 lg:grid-cols-4 gap-8 my-10">
      <DashboardCard
        class="from-blue-100 to-blue-600"
        :title="createdCount"
        subtitle="Created this month"
      >
        <sparkles-icon class="h-12 w-12 text-blue-500" />
      </DashboardCard>
      <DashboardCard
        class="from-yellow-100 to-yellow-600"
        :title="inProgressCount"
        subtitle="Awaiting Approval"
      >
        <exclamation-circle-icon class="h-12 w-12 text-orange-500" />
      </DashboardCard>
      <DashboardCard
        class="from-green-100 to-green-600"
        :title="sentCount"
        subtitle="Invoices Sent this Month"
      >
        <check-circle-icon class="h-12 w-12 text-green-500" />
      </DashboardCard>
      <DashboardCard
        class="from-gray-100 to-gray-600"
        :title="formatPrice(totalAmountCount)"
        subtitle="Total Value this Month"
      >
        <currency-dollar-icon class="h-12 w-12 text-gray-500" />
      </DashboardCard>
    </section>
  </div>
  <InvoicesTable />
</template>

<script>
import DashboardCard from '@/components/cards/DashboardCard.vue';
import InvoicesTable from '@/components/tables/InvoicesTable.vue';
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    DashboardCard,
    InvoicesTable,
  },
  setup() {
    const store = useStore();
    const createdCount = ref(0);
    const inProgressCount = ref(0);
    const sentCount = ref(0);
    const totalAmountCount = ref(0);

    onMounted(() => {
      fetchDashboardDetails();
    });

    function formatPrice(value) {
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value); 
    }

    async function fetchDashboardDetails() {
      const { invoiceCreatedCount,invoiceInProgressCount,invoiceSentCount,totalAmount } = await store.dispatch('post', {
        action: '/invoices/metrices',
      });
      createdCount.value=invoiceCreatedCount;
      inProgressCount.value=invoiceInProgressCount;
      sentCount.value=invoiceSentCount;
      totalAmountCount.value=totalAmount;
    }
    return {
      fetchDashboardDetails,
      createdCount,
      inProgressCount,
      sentCount,
      totalAmountCount,
      formatPrice 
    };
  },
};
</script>
