<template>
  <div class="card bg-gradient-radial">
    <div class="absolute top-4 right-4 z-10">
      <slot />
    </div>
    <h2 class="text-4xl text-white font-poppins font-semibold">{{ title }}</h2>
    <h3 class="text-white font-poppins font-medium">{{ subtitle }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => '',
      required: true,
    },
    subtitle: {
      type: String,
      default: () => '',
      required: true,
    },
  },
};
</script>

<style lang="postcss" scoped>
.card {
  @apply relative bg-no-repeat bg-cover rounded-3xl pt-14 pb-6 overflow-hidden px-8;
}
.card::before {
  content: '';
  @apply absolute -right-16 -bottom-36 w-64 h-64 rounded-full border-white opacity-50 border-2;
}
</style>
