<template>
  <div class="bg-gray-100 px-4 pt-16 h-full">
    <section class="lg:px-16">
      <header class="flex justify-between">
        <h1 class="text-4xl font-poppins font-normal">Invoices</h1>
        <div class="flex space-x-6">
          <div v-if="allowEditInvoice()">
            <SearchDropDown
              placeholder="Select Job"
              display-key="jobNumber"
              :items="ukzAll"
              :search-keys="['jobNumber']"
              @select="selectJob"
            >
              <template #append>
                <Button
                  class="rounded-l-none shadow-none"
                  :disabled ="!allowCreateinvoice"
                  @click="createInvoice"
                  >Create Invoice
                  <plus-icon class="inline-block w-4 h-4 ml-1 -mt-1"
                /></Button>
              </template>
            </SearchDropDown>
          </div>
          <Dropdown :items="actionItems">
            <template #trigger="{ handleToggle }">
              <Button
                @click="handleToggle"
                variant="primary"
                class="w-48 font-bold font-poppins"
              >
                Actions
                <template #append>
                  <angle-down-icon class="text-black w-4 h-4" />
                </template>
              </Button>
            </template>
          </Dropdown>
        </div>
      </header>
      <div class="mt-8 flex justify-between items-center space-x-8">
        <SearchDropDown
          class="flex-1"
          placeholder="Filter by UKZ"
          display-key="ukzNumber"
          :items="ukzAll"
          :search-keys="['ukzNumber']"
          name="ukz.ukz_number"
          @select="handleFilter"
        />
        
        <SearchDropDown
          class="flex-1"
          placeholder="Filter by Pfizer EPM"
          display-key="pfizerEpm"
          :items="getUniquePfizerEpm"
          :search-keys="['pfizerEpm']"
          name="epm"
          @select="handleEmpFilter"
        />        
        <SearchDropDown
          v-if="user && user.roleId != userRoleID.CLIENT"
          class="flex-1"
          placeholder="Filter by Status"
          display-key="title"
          :items="statuses"
          :search-keys="['title']"
          name="status.title"
          @select="handleFilter"
        />
        <form @submit.prevent="submitSearch" class="flex-1">
          <Field placeholder="Search" v-model="search">
            <template #append>
              <div class="flex items-center">
                <close-icon class="h-4 w-4 inline-block stroke-2 mr-1" @click="clearSearch()" />
                <search-icon class="w-5 h-5 mt-1 text-gray-500 ml-1" />
              </div>

            </template>
          </Field>
        </form>
      </div>
    </section>
    <section class="mt-8">
      <Table
        class="table"
        :headers="headers"
        :columns="columns"
        :data="data"
        @select="handleSelectRows"
        selectable
        striped
      >
        <template #appendColumn="{ row }">
          <td>
            <Dropdown :items="rowActionItems(row)">
              <template #trigger="{ handleToggle }">
                <button
                  @click.stop="handleToggle"
                  class="block relative right-2"
                >
                  <ellipsis-icon class="text-blue-500 w-6 h-6" />
                </button>
              </template>
            </Dropdown>
          </td>
        </template>
      </Table>
    </section>
    <section class="w-full" v-if="pagination">
      <div
        class="w-full flex justify-between items-center p-8 bg-gray-100 z-20"
      >
        <span class="font-poppins">
          Showing {{ pagination.from }} to {{ pagination.to }} of
          {{ pagination.total }}
          <span class="text-gray-300 font-semibold">|</span>
          {{ selectedRowsCount }} items selected
        </span>
        <div class="flex">
          <button
            :disabled="pagination.currentPage == 1"
            class="rounded-l-md pagination-button"
            @click="handlePrev"
          >
            Prev
          </button>
          <button
            :disabled="pagination.currentPage == pagination.lastPage"
            class="rounded-r-md pagination-button"
            @click="handleNext"
          >
            Next
          </button>
        </div>
      </div>
    </section>
    <ConfirmModal id="delete-invoices" title="Delete Invoices" />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Table from '@/components/tables/base/Table.vue';
import Dropdown from '@/components/dropdowns/Dropdown.vue';
import SearchDropDown from '@/components/dropdowns/SearchDropdown.vue';
import constant from '@/helpers/constant';

import {
  useToast,
  useModal,
  useFetch,
  useTable,
  useAllow,
} from '@/helpers/composables';

export default {
  components: {
    Table,
    Dropdown,
    SearchDropDown,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const { open: openDeleteModal } = useModal('delete-invoices');
    const { resetSelected } = useTable();
    const selectedRows = ref([]);
    const selectedRowsCount = computed(() => selectedRows.value.length);
    const ukzs = ref([]);
    const statuses = ref([]);
    const search = ref([]);
    const ukzId = ref();
    const allowCreateinvoice = ref(false);
    const { user } = store.state.user;
    const userRoleID = constant.USER_ROLE_ID;
    const ukzAll = ref([]);

    const {
      refresh,
      fetch,
      data,
      pagination,
      handleSort,
      handleFilter,
      handleSearch,
      getQueryString
    } = useFetch('/invoices');

    const actionItems = ref([
      {
        label: 'Export CSV',
        onclick: async (e) => {
          e.stopPropagation();
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,'+ encodeURIComponent(await store.dispatch('get', {
              action: `/invoices/export?${getQueryString()}`,
            }));
           anchor.target = '_blank';
          anchor.download = 'InvoicesDetails.csv';
          anchor.click();
        },
      },
      {
        label: 'Delete',
        $props: () => ({
          disabled: selectedRowsCount.value === 0,
          class: allowEditInvoice() ? null : 'hidden',
        }),
        onclick: () => {
          if (selectedRowsCount.value === 0) return;
          openDeleteModal({
            body: `Are you sure you want to delete the ${selectedRowsCount.value} selected invoices?`,
            confirm: {
              text: 'Yes, Delete',
              action: async ({ close }) => {
                const ids = Object.values(selectedRows.value);
                const { message } = await store.dispatch('post', {
                  action: '/invoices/delete',
                  formData: { ids },
                });
                resetSelected(); // this will reset table selections
                toast.show(message);
                refresh();
                close();
              },
            },
          });
        },
      },
    ]);

    const headers = ref([
      {
        label: 'Invoice #',
        name: 'invoiceNumber',
        sortAction: handleSort,
      },
      {
        label: 'UKZ #',
        name: 'ukz.ukz_number',
        sortAction: handleSort,
      },
      {
        label: 'Job Number',
        name: 'ukz.jobNumber',
        sortAction: handleSort,
      },
      {
        label: 'Description',
        name: 'description',
        sortAction: handleSort,
      },
      {
        label: 'Invoice Amount',
        name: 'ukz.scheduled_value',
        sortAction: handleSort,
      },
      {
        label: 'PO #',
        name: 'ukz.po_number',
        sortAction: handleSort,
      },
      {
        label: 'Status',
        name: 'status.title',
        sortAction: handleSort,
      },
    ]);

    const columns = ref([
      {
        name: 'invoiceNumber',
        component: 'router-link',
        render: ({ row }) => row.invoiceNumber,
        $props: ({ row }) => ({
            to: `/invoices/${row.id}`,
            class: 'font-semibold underline',
        }),
      },
      {
        name: 'ukzNumber',
        render: ({ row }) => row.ukz && row.ukz.ukzNumber,
      },
      {
        name: 'jobNumber',
        render: ({ row }) => row.ukz && row.ukz.jobNumber,
      },
      {
        name: 'description',
      },
      {
        name: 'invoiceAmount',
        render: ({ row }) => {
          if(row.lineItems.length){
            let sum = 0 ;
            row.lineItems.map((el) => {
              sum += el.currentInvoice;
            });
            var formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            });
            return formatter.format(sum);
          }
        },
      },
      {
        name: 'poNumber',
        render: ({ row }) => row.ukz && row.ukz.poNumber,
      },
      {
        name: 'status',
        component: 'Badge',
        render: ({ row }) => {
            return row.status && row.status.title;
        },
        $props: ({ row }) => {
          const variants = constant.INVOICE_STATUS_COLOR_CODING;
          return {
            variant: variants[row.status && row.status.title],
          };
        },
      },
    ]);

    onMounted(async () => {
      await fetch();
      if(user.roleId == userRoleID.CLIENT){
        await handleFilter({'name':'status.title', 'value': 'Invoice Issued'});
      }

      const [{ data: statusesData }, { data: ukzsData }, { data: ukzAllData }] = await Promise.all([
        store.dispatch('get', { action: '/invoices/statuses' }),
        store.dispatch('get', { action: '/ukzs' }),
        store.dispatch('get', {action: '/ukzs/all'})
      ]);
      statuses.value = statusesData;
      ukzs.value = ukzsData;
      ukzAll.value = ukzAllData;
    });

    const allowEditInvoice = () => allow('invoices', 'create|edit');

    const getUniquePfizerEpm = computed(() => {
      let uniqueArr = [];
      if(ukzAll.value){
        ukzAll.value.filter((value) => {
          const found = uniqueArr.some(el => el.pfizerEpm.replace(/ /g, '').toLowerCase() === value.pfizerEpm.replace(/ /g, '').toLowerCase());
          if (!found) uniqueArr.push(value);
          return ;
        });
      }
      return uniqueArr;
    });
    
    function allow(resource, action) {
      const { permissions } = store.state.user;
      const permission = useAllow(permissions, resource, action);
      return permission;
    }
    function submitSearch() {
      handleSearch('search', search.value);
    }

    function handleEmpFilter(data) {
      handleSearch(data.name, data.value);
    }

    function handlePrev() {
      resetSelected(); // this will reset table selections
      fetch(pagination.value.links.prev.replace('/api', ''));
    }

    function handleNext() {
      resetSelected(); // this will reset table selections
      fetch(pagination.value.links.next.replace('/api', ''));
    }

    function handleSelectRows({ selected }) {
      selectedRows.value = [...selected.value];
    }

    function selectJob({ item }) {
      ukzId.value = item.id;
      allowCreateinvoice.value = true;
    }

    async function createInvoice() {
      if (ukzId.value) {
        const formData = { ukzId: ukzId.value };
        const { data, message } = await store.dispatch('post', {
          action: '/invoices',
          formData,
        });
        toast.show(message);
        router.push(`/invoices/${data.id}`);
      }
    }

    function rowActionItems(row) {
      const actionItems = [
        {
          component: 'router-link',
          render: () => 'View Invoice',
          $props: () => {
            return {
              to: `/invoices/${row.id}`,
            };
          },
        },
        {
          label: 'Download PDF',
          onclick: (event) => {
            event.stopPropagation();
            downloadPDF(row.id);
          },
        },
      ];
      if(row.status.phrase == constant.INVOICE_STATUS.APPROVED) {
        actionItems.push(
          {
            component: 'router-link',
            render: () => 'Send to Pfzier',
            $props: () => {
              return {
                to: `/invoices/${row.id}`,
              };
            },
          },
        );
      }
      if(row.status.phrase == constant.INVOICE_STATUS.IN_PROGRESS){
        actionItems.push(
          {
            label: 'Send for review',
            onclick: async (event) => {
              event.stopPropagation();
              const formData = {
                statusId: constant.INVOICE_STATUS.AWAITING_APPROVAL_CSM,
              };
              try {
                const response = await store.dispatch('patch', {
                  action: `/invoices/${row.id}`,
                  formData,
                });
                toast.show(response.message);
                fetch();
              }
              catch(err){
                console.log(err);
              }
            },
          }
        );
      }
      return actionItems;
    }
    function clearSearch() {
      search.value=null;
      submitSearch(search);
    }

    async function downloadPDF(invoiceID) {
      const { data:invoiceData } = await store.dispatch('get', {
        action: `/invoices/${invoiceID}`,
      });
      var downloadLink = document.createElement("a");
      downloadLink.href = invoiceData.status.phrase ==  constant.INVOICE_STATUS.INVOICE_ISSUED ? invoiceData.concatenatedDocUrl :invoiceData.documentUrl;
      downloadLink.target = '_blank';
      downloadLink.click();
    }

    return {
      ukzs,
      headers,
      columns,
      data,
      pagination,
      handleSort,
      handleFilter,
      rowActionItems,
      selectedRows,
      handleSelectRows,
      selectedRowsCount,
      actionItems,
      statuses,
      search,
      submitSearch,
      handlePrev,
      handleNext,
      selectJob,
      createInvoice,
      allowEditInvoice,
      allowCreateinvoice,
      clearSearch,
      userRoleID,
      user,
      ukzAll,
      handleEmpFilter,
      getUniquePfizerEpm
    };
  },
};
</script>
